<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
        <div class="clickBox1" @click="gotoLink"></div>
        <div class="clickBox2" @click="gotoLink"></div>
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <img :src="importedImg3" alt="" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <!-- <img :src="importedImg4" alt="" class="imgItem4" /> -->
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <!-- <img :src="importedImg5" alt="" class="imgItem5" />
        <div class="clickBox3" @click="gotoLink"></div>
        <div class="clickBox4" @click="gotoPage"></div> -->
      </div>
    </div>
    <!-- <div class="imgBox6 min-w-1400">
        <div class="img6Box max-w-1400">
          <img :src="importedImg6" alt="" class="imgItem6" />
        </div>
    </div> -->
    <!-- <div class="imgBox7 min-w-1400">
        <div class="img7Box max-w-1400">
          <img :src="importedImg7" alt="" class="imgItem7" />
        </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/conficreosofom/components/img/1_1.png";
import importedImg2 from "@/page/conficreosofom/components/img/1_2.png";
import importedImg3 from "@/page/conficreosofom/components/img/1_3.png";
// import importedImg4 from "@/page/conficreosofom/components/img/1_4.png";
// import importedImg5 from "@/page/conficreosofom/components/img/1_5.png";
// import importedImg6 from "@/page/conficreosofom/components/img/1_6.png";
// import importedImg7 from "@/page/conficreosofom/components/img/1_7.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      // importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    gotoLink() {
      window.open("https://www.buro.gob.mx", "_blank");
    },
    gotoPage() {
      this.$router.push({ path: '/protocol' });
    }
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}



.img2Box {
  margin: 60px 0;
}

.img2Box {
  position: relative;
}

.clickBox1 {
  position: absolute;
  width: 64px;
  height: 31px;
  top: 70.5%;
  left: 76.2%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.clickBox2 {
  position: absolute;
  width: 164px;
  height: 31px;
  top: 76%;
  left: 56.2%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.img5Box {
  position: relative;
}

.clickBox3 {
  position: absolute;
  width: 210px;
  height: 27px;
  top: 89%;
  left: 52.5%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.clickBox4 {
  position: absolute;
  width: 100px;
  height: 32px;
  top: 36.5%;
  left: 13%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}
</style>
