<template>
  <nav class="navbar min-w-1400" :class="{ 'hidden': $route.path === '/protocol' }">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/betacreditdinero/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">Conficreo, S.A.P.I. de C.V., SOFOM, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Inicio</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Sobre nosotros</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Preguntas frecuentes</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <a href="https://clmiw.ficreocreditconapp.com/help/privacy_agreement" class="nav-link3" target="_blank">
            <span class="routeText4">Acuerdo de privacidad</span>
          </a>
        </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->
          <span class="dropdown-toggle routeText5">Política de privacidad</span>
          <ul class="dropdown-menu">
            <li class="firstLi">
              <a
                href="https://clmiw.ficreocreditconapp.com/help/privacy_agreement"
                target="_blank"
              >
                <img src="@/page/conficreosofom/components/img/app.png" alt="App Store" class="iconImg"/>
               App Store</a
              >
            </li>
            <li>
              <a
                href="https://clmaw.ficreocreditconapp.com/help/privacy_agreement"
                target="_blank"
                >
                <img src="@/page/conficreosofom/components/img/play.png" alt="App Store" class="iconImg"/>
                Google Play</a
              >
            </li>
          </ul>
        </li>
        <!-- <li class="dropdown Li4" :class="{ 'active-link': $route.path === '/protocol' }" @mousemove="isShow = true"
          @mouseleave="isShow = false">
          <span class="dropdown-toggle routeText4">Acuerdo de privacidad</span>
          <img :src="require(isShow
          ? '@/page/onsenmobile/components/img/img.png'
          : '@/page/onsenmobile/components/img/1.png')
          " alt="" />
          <ul class="dropdown-menu" v-if="isShow">
            <li>
              <a href="https://clmiw.ficreocreditconapp.com/help/privacy_agreement" @click="() => { isShow = false }"
                target="_blank"><img src="@/page/conficreosofom/components/img/app.png" alt="App Store"
                  class="iconImg" />Appstore</a>
            </li>
            <li>
              <a href="https://clmaw.ficreocreditconapp.com/help/privacy_agreement" @click="() => { isShow = false }"
                target="_blank"><img src="@/page/conficreosofom/components/img/play.png" alt="App Store"
                  class="iconImg" />GooglePlay</a>
            </li>
          </ul>
        </li> -->
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
      isShow: false,
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    showOptions() {
      if (this.isShow) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #FFFFFF;
  /* box-shadow: 0 2px #682F1C; */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  height: 24px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #0A0A0A;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  line-height: 35px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #9D9D9D;
  line-height: 29px;
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 6px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 39px;
  padding-left: 4px;
  padding-right: 4px;
}

.Li1 {
  width: 120px;
}

.Li2 {
  width: 180px;
}

.Li3 {
  width: 220px;
}

.Li4 {
  width: 300px;
  margin-left: 20px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.active-link {
  border-color: #8453E4;
  background-color: #8453E4;
  /* border-radius: 40px; */
  border-radius: 10px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  color: #ffffff;
}

.title {
  padding-left: 110px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0px 0;
  min-width: 200px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 15px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  margin-left: 30px;
  margin-top: 2px;
  display: flex;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
.firstLi{
  border-bottom: 1px solid rgb(212, 212, 212);

}
.iconImg{
  width: 18px;
  height: 18px;
  padding-left: -20px;
  margin: 0 10px 0 auto;
}
.hidden{
  display: none;
}
</style>