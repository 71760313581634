<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="importedImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <!-- <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <h1>Política de privacidad</h1>
        <div class="title-line"></div>
        <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
      </div>
    </div> -->
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
  </div>
</template>

<script>
// import importedImg1 from "@/page/conficreosofom/components/img/4_1.png";
import importedImg3 from "@/page/conficreosofom/components/img/4_1.png";

export default {
  data() {
    return {
      // importedImg1: importedImg1,
      importedImg3: importedImg3,
      text: `Esta es una herramienta de asesoramiento y comunicación a través de la cual podrá conocer los productos que ofrecen las entidades financieras, comisiones y honorarios, quejas de los usuarios, prácticas insalubres en las que incurren, sanciones administrativas que les imponen, cláusulas abusivas en sus contratos y otros asuntos relevantes para informar. usted información sobre su desempeño.
Con el Negociado de Entidades Financieras es posible saber quién es quién en bancos, compañías de seguros, sociedades financieras de objeto múltiple, cajas de ahorro, bancos y otras entidades.
Con esto podrás comparar y evaluar instituciones financieras, sus productos y servicios, y tendrás más elementos para elegir la mejor para ti.
Esta información le ayudará a seleccionar productos financieros y también a comprender y utilizar mejor los productos que ya posee.
La Oficina de Entidades Financieras es una herramienta que puede contribuir al crecimiento económico del país promoviendo la competencia entre las instituciones financieras, lo que aumentará la transparencia al revelar a los usuarios información sobre el desempeño de las instituciones financieras y los productos que ofrecen, y al brindar información detallada conocimiento de los productos financieros y las características del servicio para promover una gestión responsable.
Esto podría redundar en un mayor bien social, pues al combinar información tan diversa sobre el sistema financiero en un solo espacio, los usuarios tendrán más elementos para optimizar sus presupuestos y mejorar sus finanzas personales, el uso correcto del crédito fortalecerá sus finanzas y obtendrá seguros que los protege, entre otras cosas.
La Dirección de Entidades Financieras contiene información de Conficreo, S.A.P.I. de C.V., SOFOM, E.N.R. sobre su desempeño en la prestación de productos y servicios con Argentina (Player). Para conocer sobre nosotros u otras instituciones, lo invitamos a consultar el sitio web http://www.buro.gob.mx/.`,

    };
  },
  created() {
    this.gotoTop();
  },
  methods:{
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  /* background-color: #FAFAFA; */
}

.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 5300px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}

.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.imgItem2 {
  max-width: 100%;
}

.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

h1 {
  /* margin-top: 50px; */
  margin-left: 490px;
  color: #17504C;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}

.textBox {
  /* margin-top: 50px; */
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}

.imgBox2 {
  transform: scale(93%);
  margin: -30px auto 0;
  height: 900px;
  font-size: 26px;
  line-height: 42px;
  /* box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25); */
}

.title-line {
  width: 430px;
  background: url(./components/img/Group1.png);
  background-repeat: no-repeat;
  background-position: -150px 10px;
  height: 50px;
  /* border: 1px solid red; */
  /* border: 5px solid #1ADD4C; */
  margin: 0 490px;
}
.img1{
  height: 90px;
  background-color: #8453E4;
}
.img3{
  padding: 0 250px;
  margin: 80px 0 100px;
}
</style>